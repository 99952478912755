import { computed } from 'vue'
import { updateQuery } from '@/utils/update-query'
import { app } from '@/config'

const useProductQueryParams = (route) => {
  // basics
  const query = computed(() => route.query)
  const page = computed(() => query.value.page || 1)
  const sort = computed(() => query.value.sort || 'name,ASC')
  // customs
  const category = computed(() => query.value.category || [])
  const compareFromStoreCode = computed(() => query.value.compareFromStoreCode || '')
  const compareToStoreCode = computed(() => query.value.compareToStoreCode || '')
  const minStockToStoreCode = computed(() => query.value.minStockToStoreCode || '')
  const reverseStock = computed(() => Boolean(query.value.reverseStock))
  const minPrice = computed(() => query.value.minPrice || '')
  const maxPrice = computed(() => query.value.maxPrice || '')
  const readyStock = computed(() => Boolean(query.value.readyStock))
  const absolute = computed(() => Boolean(query.value.absolute))
  const hasTokopediaLink = computed(() => Boolean(query.value.hasTokopediaLink))
  const hasTiktokLink = computed(() => Boolean(query.value.hasTiktokLink))
  const hasShopeeLink = computed(() => Boolean(query.value.hasShopeeLink))

  return {
    page: parseInt(page.value),
    sort: sort.value,
    // customs
    category: Array.isArray(category.value) ? category.value : [category.value],
    compareFromStoreCode: compareFromStoreCode.value,
    compareToStoreCode: compareToStoreCode.value,
    minStockToStoreCode: minStockToStoreCode.value,
    reverseStock: reverseStock.value,
    minPrice: minPrice.value,
    maxPrice: maxPrice.value,
    readyStock: readyStock.value,
    absolute: absolute.value,
    hasTokopediaLink: hasTokopediaLink.value,
    hasTiktokLink: hasTiktokLink.value,
    hasShopeeLink: hasShopeeLink.value
  }
}

const useStockSummaryQueryParams = (route) => {
  // basics
  const query = computed(() => route.query)
  const page = computed(() => query.value.page || app.defaultPageStart)
  const size = computed(() => query.value.size || app.shorterPageSize)
  const sort = computed(() => query.value.sort || 'name,ASC')
  // customs
  const productName = computed(() => query.value.productName || '')
  const category = computed(() => query.value.category || [])
  const readyStock = computed(() => Boolean(query.value.readyStock))
  const hasTokopediaLink = computed(() => Boolean(query.value.hasTokopediaLink))
  const hasTiktokLink = computed(() => Boolean(query.value.hasTiktokLink))
  const hasErablueSalePrice = computed(() => Boolean(query.value.hasErablueSalePrice))
  const hasTokopediaPriceAdjustment = computed(() => Boolean(query.value.hasTokopediaPriceAdjustment))
  const hasShopeePriceAdjustment = computed(() => Boolean(query.value.hasShopeePriceAdjustment))
  const hasTiktokPriceAdjustment = computed(() => Boolean(query.value.hasTiktokPriceAdjustment))

  return {
    page: parseInt(page.value),
    size: parseInt(size.value),
    sort: sort.value,
    // customs
    productName: productName.value,
    category: Array.isArray(category.value) ? category.value : [category.value],
    readyStock: readyStock.value,
    hasTokopediaLink: hasTokopediaLink.value,
    hasTiktokLink: hasTiktokLink.value,
    hasErablueSalePrice: hasErablueSalePrice.value,
    hasTokopediaPriceAdjustment: hasTokopediaPriceAdjustment.value,
    hasShopeePriceAdjustment: hasShopeePriceAdjustment.value,
    hasTiktokPriceAdjustment: hasTiktokPriceAdjustment.value
  }
}

const usePurchaseOrderQueryParams = (route) => {
  const tabs = app.purchaseOrder.storeTabs.map(tab => ({
    value: tab.value,
    text: tab.text
  }))

  // basics
  const query = computed(() => route.query)
  // customs
  const search = computed(() => query.value.search || '')
  const status = computed(() => query.value.status || tabs[0].value || '')

  return {
    // customs
    search: search.value,
    status: status.value
  }
}

const useStockInQueryParams = (route) => {
  // basics
  const query = computed(() => route.query)
  // customs
  const storeCode = computed(() => query.value.storeCode || 'MG')
  const productName = computed(() => query.value.productName || '')

  return {
    // customs
    storeCode: storeCode.value,
    productName: productName.value
  }
}

const useStoreTransactionQueryParams = (route, initialStoreCode = '') => {
  // basics
  const query = computed(() => route.query)
  // customs
  const storeCode = computed(() => query.value.storeCode || initialStoreCode)
  const date = computed(() => query.value.date || '')

  return {
    // customs
    storeCode: storeCode.value,
    date: date.value
  }
}

/**
 * params should be using ref instead of reactive
 * @param route
 * @param router
 * @param params
 */
const useSubmitSearch = (route, router, params) => {
  updateQuery(router, { ...route.query, ...params.value })
}

const serializeQueryParams = (paramObj) => {
  if (paramObj) {
    return '?' + Object.keys(paramObj).map(k => {
      const value = paramObj[k]

      if (value && typeof value === 'object') {
        return value.map(v => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`).join('&')
      } else {
        return `${encodeURIComponent(k)}=${encodeURIComponent(value)}`
      }
    }).join('&')
  }
  return ''
}

const navigateToCleanUrl = (router) => {
  router.replace(router.currentRoute.value.path)
}

export {
  useProductQueryParams,
  useStockSummaryQueryParams,
  usePurchaseOrderQueryParams,
  useStockInQueryParams,
  useStoreTransactionQueryParams,
  useSubmitSearch,
  serializeQueryParams,
  navigateToCleanUrl
}
